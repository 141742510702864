<template>
  <div class="narrowBox my-setting">
    <el-tabs
      :tab-position="tabPosition"
      class="settings-tabs"
      v-model="active"
      :before-leave="beforeHandleTab"
    >
      <el-tab-pane name="setting">
        <div slot="label" class="settingSecurity tabTitle">
          <div class="securityIcon iconCommonStyle"></div>
          <span class="settingTitle" :style="{ marginLeft: '17px' }">
            {{ $t("accountSecurity") }}
          </span>
        </div>
        <div class="setting accountAecurity">
          <div class="userinfo__base">
            <div class="settingBlockTitle">
              {{ $t("currentlyLoggedInAccount") }}
            </div>
            <div class="userInfoBox">
              <div class="userinfoLeft">
                <div class="avatarBox">
                  <Avatar
                    :path="$store.getters.userInfo.avatar"
                    :providerId="$store.getters.userInfo.id"
                    :international="0"
                  ></Avatar>
                </div>
                <div
                  class="userNameBox"
                  :class="$store.getters.userInfo.vipStatus ? 'vipName' : ''"
                >
                  {{ $store.getters.userInfo.name }}
                  <el-tooltip
                    class="item"
                    effect="dark"
                    :content="$t('paidMember')"
                    placement="bottom"
                    popper-class="me-tooltip-effect"
                  >
                    <VipIcon
                      :vipStatus="$store.getters.userInfo.vipStatus"
                      :iconWidth="20"
                      :iconHeight="20"
                      :id="$store.getters.userInfo.id"
                    />
                  </el-tooltip>
                </div>
                <div class="userIdBox">
                  <div class="userIdIcon">
                    <img
                      :src="
                        require('../assets/images/newPersonalCenter/idIcon.png')
                      "
                    />
                  </div>
                  <div class="userIdText">
                    {{ $store.getters.userInfo.uc_id }}
                  </div>
                </div>
              </div>
              <div class="userinfoRight">
                <img
                  :src="dunImg"
                  style="width:56px;height:56px;object-fit:cover"
                  alt=""
                />
                <div class="secureLeaveBox">
                  <span class="secureLeaveTitle">
                    {{ $t("secureLeave") }}
                  </span>
                  <span class="secureLevelText">
                    {{
                      `V${$store.state.accountStore.secureInfo.secureLevel ||
                        1}`
                    }}
                  </span>
                </div>
                <div class="pross">
                  <el-progress
                    :show-text="false"
                    :stroke-width="18"
                    :percentage="percentage"
                    :class="[
                      'color-' +
                        $store.state.accountStore.secureInfo.secureLevel,
                    ]"
                  ></el-progress>
                  <div
                    class="tip"
                    @click="
                      jumpPage({
                        name: 'wallet',
                        params: {
                          type: 'setWallet',
                        },
                      })
                    "
                  >
                    {{
                      !$store.state.accountStore.secureInfo.hasSecureWallet
                        ? $t("setPaypwd")
                        : !$store.state.accountStore.secureInfo
                            .hasWalletStrongPwd
                        ? $t("modifyPaypwd")
                        : ""
                    }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="accountSetting_box">
            <AccountSetting></AccountSetting>
          </div>
        </div>
      </el-tab-pane>
      <el-tab-pane name="settingSecurity">
        <div slot="label" class="settingSecurity tabTitle">
          <div class="privacyIcon iconCommonStyle"></div>
          <span class="settingTitle" :style="{ marginLeft: '17px' }">
            {{ $t("privacyTitle") }}
          </span>
        </div>
        <div class="setting securityContent">
          <div class="contentHeader">
            {{ $t("privacyProtection") }}
          </div>
          <div class="contentBox">
            <div class="contentItem allowFriendsToChat">
              <span class="itemTitle">{{ $t("allowFriendsToChat") }}</span>
              <el-switch v-model="allowToChat" @change="changeAllowToChat">
              </el-switch>
            </div>
          </div>
        </div>
      </el-tab-pane>
      <el-tab-pane name="autoForwarded">
        <div slot="label" class="settingSecurity tabTitle">
          <div class="forwardIcon iconCommonStyle"></div>
          <span class="settingTitle">
            {{ $t("automaticForwarding") }}
          </span>
        </div>
        <div class="setting tranpane">
          <AutoforwardingForSettingPage
            :curUser="curUser"
            @delTranUser="delTranUser"
            :handleFriendsList="handleFriendsList"
            @chooseThisFriend="chooseThisFriend"
          ></AutoforwardingForSettingPage>
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
import AccountSetting from "./accountSetting";
import { send } from "@/components/business/chat/server";
import UUID from "uuidjs";
import Avatar from "../newVersion/components/avatarDisplay";
import * as RESTApi from "@/api/rest";
import AutoforwardingForSettingPage from "../newVersion/components/autoforwardingForSettingPage";
import VipIcon from "@/newVersion/components/icons/IconVip.vue";
export default {
  name: "Setting",
  data() {
    return {
      tabPosition: "left",
      langcode: 1,
      walletUrl: "",
      active: "setting",
      form: {
        pwd: "",
        repwd: "",
      },
      rules: {
        pwd: { required: true, message: " ", trigger: "blur" },
        repwd: { required: true, message: " ", trigger: "blur" },
      },
      friends: [],
      filterFriends: [],
      searchkey: "",
      curUser: {},
      showSearchBox: true,
      percentage: 20,
      color: "",
      dunImg: "",
      // 仅允许好友聊天
      allowToChat: false,
    };
  },
  components: {
    Avatar,
    AccountSetting,
    AutoforwardingForSettingPage,
    VipIcon,
  },
  watch: {
    searchkey: {
      handler() {
        this.filterFriend();
      },
      dreep: true,
    },
    active: {
      handler(val) {
        // 如果选择了设置，并且状态管理器中安全信息不为空,别去用户ID或者用户ucid
        if (
          val === "setting" &&
          !Object.keys(this.$store.state.accountStore.secureInfo).length &&
          (this.$store.getters.userInfo.account ||
            this.$store.getters.userInfo.id)
        ) {
          this.$store.dispatch(
            "commitSetSecureInfo",
            this.$store.getters.userInfo.account ||
              this.$store.getters.userInfo.id
          );
        }
      },
      deep: true,
    },
    "$store.state.accountStore.secureInfo.secureLevel": {
      handler(val) {
        if (this.$store.state.accountStore.secureInfo.secureLevel) {
          this.dunImg = require(`../assets/securs/anquan${this.$store.state.accountStore.secureInfo.secureLevel}.png`);
          this.percentage =
            (parseInt(this.$store.state.accountStore.secureInfo.secureLevel) /
              5) *
            100;
        }
      },
      dreep: true,
    },
  },
  async created() {
    this.$store.dispatch("commitMenuStatus", false);
    await this.getFriends();
    let langStr = localStorage.getItem("langCode");
    this.langcode = langStr ? parseInt(langStr) : 1;
    this.active = this.$route.params.type || "setting";
    // 获取自动转发用户列表
    await this.getForwardUser();
    // 每次创建设置组件时更新用户安全信息
    await this.handlerGetSecurInfo();
  },
  computed: {
    // 处理过的好友列表
    handleFriendsList() {
      let handleList = this.filterFriends.reduce((item, next) => {
        if (item.length !== 0) {
          if (
            item.findIndex(
              (value) => value.name_first_spell === next.name_first_spell
            ) === -1
          ) {
            item.push({
              name_first_spell: next.name_first_spell,
              childrens: [next],
            });
          } else {
            item[
              item.findIndex(
                (value) => value.name_first_spell === next.name_first_spell
              )
            ].childrens.push(next);
          }
        } else {
          item.push({
            name_first_spell: next.name_first_spell,
            childrens: [next],
          });
        }
        return item;
      }, []);
      return handleList;
    },
  },
  methods: {
    // tab跳转前判断
    async beforeHandleTab(activeName, oldActiveName) {
      if (activeName == "customerService") {
        this.chatContanct();
        return false;
      }
      if (activeName == "settingSecurity") {
        await this.getFriendChatOnly();
        return true;
      }
    },
    // 获取自己是否开启仅允许好友聊天
    async getFriendChatOnly() {
      let result = await RESTApi.getFriendChatOnly();
      if (result.code === 200) {
        this.allowToChat = result.data.data == 0 ? false : true;
      } else {
        this.$message({
          type: "error",
          message: result.message,
        });
      }
    },
    // 切换允许好友聊天状态
    async changeAllowToChat() {
      let params = {
        value: this.allowToChat ? 1 : 0,
      };
      let result = await RESTApi.setFriendChatOnly(params);
      if (result.code === 200) {
      } else {
        this.$message({
          type: "error",
          message: result.message,
        });
      }
    },
    // 点击联系客服
    async chatContanct() {
      let userCatch = this.$store.getters.userInfo;
      if (userCatch.groupId) {
        this.$openIM({
          id: userCatch.groupId,
          displayName: userCatch.groupName,
          avatar: userCatch.groupHeader,
          isGroup: true,
        });
      }
    },
    // 更新用户安全信息
    async handlerGetSecurInfo() {
      // 如果是第三方用户的话，就用userinfo.id来获取用户的安全信息
      await this.$store.dispatch(
        "commitSetSecureInfo",
        this.$store.getters.userInfo.account || this.$store.getters.userInfo.id
      );
      if (this.$store.state.accountStore.secureInfo.secureLevel) {
        this.dunImg = require(`../assets/securs/anquan${this.$store.state.accountStore.secureInfo.secureLevel}.png`);
        this.percentage =
          (parseInt(this.$store.state.accountStore.secureInfo.secureLevel) /
            5) *
          100;
      }
    },
    async loginoutFunc() {
      if (this.$cookies.get("oauth_user_id"))
        this.$cookies.remove("oauth_user_id");
      await this.$http({
        url: "/helome/logout.do",
        method: "get",
      }).catch((e) => {
        console.log(e + "from src/views/setting.vue:267:16:");
      });
    },
    // 选择这个好友
    chooseThisFriend(user) {
      this.filterFriends = this.filterFriends.map((item) => {
        item.hasSelected = false;
        if (item.provider_id === user.provider_id) {
          item.hasSelected = true;
        }
        return item;
      });
      this.$forceUpdate();
      this.$nextTick(() => {
        this.setTranUser(user);
      });
    },
    async setTranUser(user) {
      let data = {
        code: 10001,
        messageId: UUID.generate(),
        to: "forward.melinked.com",
        from:
          this.$store.getters.userInfo.id +
          "@melinked.com/chrome_69.0.3497.100",
        header: { cmd: 101001 },
        protocal: { proxyuid: user.provider_id },
        type: "set",
      };
      send(data);
      this.curUser = user;
    },
    delTranUser() {
      let data = {
        code: 10001,
        messageId: UUID.generate(),
        to: "forward.melinked.com",
        from:
          this.$store.getters.userInfo.id +
          "@melinked.com/chrome_69.0.3497.100",
        header: { cmd: 101002 },
        type: "set",
      };
      send(data);
      this.curUser = {};
      this.filterFriends = this.filterFriends.map((item) => {
        item.hasSelected = false;
        return item;
      });
      this.$forceUpdate();
    },
    // 获取自动转发用户列表
    async getForwardUser() {
      let params = { language: this.langcode };
      let result = await this.$http({
        url: `rest/user/${this.$store.getters.userInfo.id}/forward`,
        params,
        withCredentials: false,
      }).catch((e) => {
        console.log(e + "from src/views/setting.vue:305:16:");
      });
      if (result && Object.keys(result.data).length) {
        let data = result.data;
        let user = {
          name: data.name,
          avatar: data.profile_photo,
          provider_id: data.provider_id,
          country: data.country,
          uc_id: data.uc_id,
        };
        this.curUser = user;
        this.filterFriends = this.filterFriends.map((item) => {
          item.hasSelected = false;
          if (item.provider_id === this.curUser.provider_id) {
            item.hasSelected = true;
          }
          return item;
        });
        this.$forceUpdate();
      }
    },
    // 获取好友列表
    async getFriends() {
      let params = {
        _im: "web",
        page: 0,
        pageSize: 4095,
        _: Date.now(),
      };
      const result = await this.$http({
        url: "public/queryFriend.do",
        params,
      }).catch((e) => {
        console.log(e + "from src/views/setting.vue:330:16:");
      });
      if (result && result.data && result.data.list) {
        this.filterFriends = result.data.list;
        this.friends = result.data.list;
      }
    },
    // 用于搜索
    filterFriend() {
      this.filterFriends = this.friends.filter((item) => {
        if (
          item.name &&
          item.name.toLowerCase().indexOf(this.searchkey.toLowerCase()) !== -1
        ) {
          return item;
        }
      });
    },
    submitForm() {
      this.$refs.repPwdForm.validate((valid) => {
        if (valid) {
          this.updatePwd();
        } else {
          this.$message({
            type: "error",
            message: this.$t("changeFail"),
          });
        }
      });
    },
    clearCurUser() {},
    async updatePwd() {
      let params = {
        oldPassword: this.fnencrypt(this.form.pwd),
        newPassword: this.fnencrypt(this.form.repwd),
        language: localStorage.getItem("langCode"),
        userId: this.$store.getters.userInfo.id,
        device: "web",
      };
      let result = await this.$http({
        url: "rest/user/updatePassword",
        params,
        method: "post",
        withCredentials: false,
      }).catch((e) => {
        console.log(e + "from src/views/setting.vue:370:16:");
        this.$message({
          type: "error",
          message: this.$t("changeFail"),
        });
      });
      if (result.code === 200) {
        this.$message({
          type: "success",
          message: this.$t("updateSuccess"),
        });
        this.$store.commit("setUserinfo", {});
        this.loginoutFunc();
        this.jumpPage({ name: "home" });
      }
    },
  },
};
</script>
<!-- 5.1.0新改的样式，下面没有scoped是以前的老样式，先不动，直接覆盖 -->
<style lang="stylus" scoped>
.my-setting
  width 100vw !important;
  max-width 100vw !important;
  height calc(100vh - 60px);
  padding 10px 0 !important;
  margin 0 !important;
  box-sizing border-box;
  background #F7F7F7;
  .is-active
    .securityIcon
      background url("../assets/images/newSetting/safetyIcon_light.png") no-repeat !important;
    .privacyIcon
      background url("../assets/images/newSetting/privacyIcon_light.png") no-repeat !important;
    .forwardIcon
      background url("../assets/images/newSetting/forwardIcon_light.png") no-repeat !important;
  .settings-tabs
    width 1160px;
    overflow hidden;
    margin auto;
    display flex;
    .el-icon-setting
      span
        font-size 14px;
        margin-left: 0.5rem;
    .tabTitle
      width 100%;
      height 100%;
      display flex;
      padding 0 20px;
      align-items center;
      justify-content flex-start;
      border-bottom 1px solid #e5e5e5;
    .settingSecurity
      &:hover
        .securityIcon
          background url("../assets/images/newSetting/safetyIcon_light.png") no-repeat;
        .privacyIcon
          background url("../assets/images/newSetting/privacyIcon_light.png") no-repeat;
        .forwardIcon
          background url("../assets/images/newSetting/forwardIcon_light.png") no-repeat;
      .iconCommonStyle
        height: 16px;
        background-size 100% 100%;
      .securityIcon
        width: 14px;
        background url("../assets/images/newSetting/safetyIcon.png") no-repeat;
      .privacyIcon
        width: 14px;
        background url("../assets/images/newSetting/privacyIcon.png") no-repeat;
      .forwardIcon
        width: 16px;
        background url("../assets/images/newSetting/forwardIcon.png") no-repeat;
      .settingTitle
        margin-left 15px;
        font-size 14px;
    .accountAecurity
      width 100%;
      overflow hidden;
      .userinfo__base
        display flex;
        flex-flow column;
        .settingBlockTitle
          width 100%;
          box-sizing border-box;
          padding 70px 0 0 80px;
          font-size 18px;
          color #373737;
          font-weight bold;
        .userInfoBox
          width 100%;
          box-sizing border-box;
          padding 40px 80px 0 80px;
          display flex;
          flex-flow row;
          align-items flex-start;
          justify-content space-between;
          .userinfoLeft
            display flex;
            flex-flow column;
            .avatarBox
              width 90px;
              height 90px;
            .userNameBox
              margin-top 20px;
              font-size 16px;
              color #373737;
              text-align left;
              line-height 24px;
              font-weight bold;
            .userIdBox
              margin-top 10px;
              display flex;
              align-items center;
              justify-content flex-start;
              .userIdIcon
                height 15px;
                margin-right 10px;
                img
                  width 100%;
                  height 100%;
                  object-fit: contain;
              .userIdText
                font-size 14px;
                color #666666;
          .userinfoRight
            width 180px;
            overflow hidden;
            display flex;
            flex-flow column;
            align-items center;
            .secureLeaveBox
              width 100%;
              margin-top 15px;
              display flex;
              align-items center;
              justify-content center;
              .secureLeaveTitle
                font-size 12px;
                color #343434;
              .secureLevelText
                margin-left 8px;
                font-size 14px;
                font-weight bold;
                color #343434;
            .pross
              width 100%;
              margin-top 20px;
              .leave
                font-size 15px
                color #666666
              .tip
                margin-top 12px;
                font-size 12px;
                cursor pointer;
                text-decoration underline;
      .accountSetting_box
        padding 0 80px;
    .securityContent
      height calc(100vh - 80px);
      .contentHeader
        width 100%;
        box-sizing border-box;
        padding 70px 0 0 80px;
        font-size 18px;
        color #373737;
        font-weight bold;
        .headerIcon
          width: 17px;
          height: 19px;
          object-fit: cover;
          margin-left 1.5rem;
          background url("../assets/images/setting/securityIcon.png") no-repeat;
          background-size 100% 100%;
      .contentBox
        width 100%;
        margin-top 40px;
        padding 0 80px;
        .contentItem
          width 100%;
          height 60px;
          display flex;
          flex-flow row;
          align-items center;
          justify-content space-between;
          border-bottom 1px solid #e5e5e5;
          .itemTitle
            font-size 14px;
            color: #373737;
    .tranpane
      width: 100%;
      overflow hidden;
      height calc(100vh - 80px);
</style>
<!---------------------------------------- --------------------------------------------------->
<style lang="stylus">
@import '~styles/utils/index';
  .accountAecurity
    .userinfoRight
      .pross
        .color-1
          .el-progress-bar
            .el-progress-bar__outer
              .el-progress-bar__inner
                  background #f86774 !important
        .color-2
          .el-progress-bar
            .el-progress-bar__outer
              .el-progress-bar__inner
                  background #f8bb37 !important
        .color-3
          .el-progress-bar
            .el-progress-bar__outer
              .el-progress-bar__inner
                  background #8a73f8 !important
        .color-4
          .el-progress-bar
            .el-progress-bar__outer
              .el-progress-bar__inner
                  background #5ab1fa !important
        .color-5
          .el-progress-bar
            .el-progress-bar__outer
              .el-progress-bar__inner
                  background #3fd79a !important
  .my-setting
    .el-tabs
      +e(header)
        background #ffffff
        border-top 1px solid #e5e5e5
        border-bottom 1px solid #e5e5e5
        border-left 1px solid #e5e5e5
      +e(item)
        &:last-child
          .tabTitle
            border-bottom 0
    .settings-tabs
      .el-tabs__content
        flex 1;
        min-width 0;
        background #ffffff
        border 1px solid #e5e5e5
      .el-tabs__header
        width 282px !important;
        flex-shrink 0;
        display: flex;
        .el-tabs__nav-wrap
          flex: 1;
        .el-tabs__item
          height 58px;
          width 100%;
          display: flex;
          padding 0;
          align-items: center;
          box-sizing: border-box;
</style>
