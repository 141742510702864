var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "autoforwardingBox" }, [
    _c(
      "div",
      { staticClass: "autoforwardingSwitchBox" },
      [
        _vm._v("\n    " + _vm._s(_vm.$t("automaticForwarding")) + "\n    "),
        _c("el-switch", {
          model: {
            value: _vm.allowChangeAutoforwarding,
            callback: function($$v) {
              _vm.allowChangeAutoforwarding = $$v
            },
            expression: "allowChangeAutoforwarding"
          }
        })
      ],
      1
    ),
    _vm.allowChangeAutoforwarding
      ? _c("div", { staticClass: "autoforwardingInner" }, [
          _vm.curUser.provider_id
            ? _c("div", { staticClass: "curUserBox" }, [
                _c("div", { staticClass: "curUserInner" }, [
                  _c(
                    "div",
                    { staticClass: "curUserAvatar" },
                    [
                      _c("Avatar", {
                        attrs: {
                          path: _vm.curUser.avatar,
                          international: _vm.curUser.country
                            ? Number(_vm.curUser.country)
                            : 0
                        }
                      })
                    ],
                    1
                  ),
                  _c("div", { staticClass: "curUserInfo" }, [
                    _c("div", { staticClass: "firstRowBox" }, [
                      _c("div", { staticClass: "friendName" }, [
                        _vm._v(
                          "\n              " +
                            _vm._s(_vm.curUser.name) +
                            "\n            "
                        )
                      ])
                    ]),
                    _c("div", { staticClass: "secondRowBox" }, [
                      _vm._v(
                        "\n            ID: " +
                          _vm._s(_vm.curUser.uc_id) +
                          "\n          "
                      )
                    ])
                  ]),
                  _c("div", { staticClass: "curUserCloseBox" }, [
                    _c(
                      "div",
                      {
                        staticClass: "closeIcon",
                        on: {
                          click: function($event) {
                            $event.stopPropagation()
                            return _vm.delTranUser.apply(null, arguments)
                          }
                        }
                      },
                      [
                        _c("span", {
                          staticClass: "iconfont_Me icon-x iconStyle"
                        })
                      ]
                    )
                  ])
                ])
              ])
            : _vm._e(),
          _vm._m(0),
          _c(
            "div",
            { staticClass: "autoforwardingContent" },
            _vm._l(_vm.handleFriendsList, function(item, index) {
              return _c(
                "div",
                { key: item.name_first_spell, staticClass: "blockItem" },
                [
                  _c("div", { staticClass: "itemTitle" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(item.name_first_spell) +
                        "\n        "
                    )
                  ]),
                  _vm._l(item.childrens, function(element) {
                    return _c(
                      "div",
                      {
                        key: element.provider_id,
                        staticClass: "friendItem",
                        on: {
                          click: function($event) {
                            $event.stopPropagation()
                            return _vm.chooseThisFriend(element)
                          }
                        }
                      },
                      [
                        _c("div", { staticClass: "itemLeft" }, [
                          _c(
                            "div",
                            { staticClass: "friendAvatar" },
                            [
                              _c("Avatar", {
                                attrs: {
                                  path: element.avatar,
                                  international: element.country
                                    ? Number(element.country)
                                    : 0
                                }
                              })
                            ],
                            1
                          ),
                          _c("div", { staticClass: "friendInfoBox" }, [
                            _c("div", { staticClass: "firstRowBox" }, [
                              _c("div", { staticClass: "friendName" }, [
                                _vm._v(
                                  "\n                  " +
                                    _vm._s(element.name) +
                                    "\n                "
                                )
                              ]),
                              _c(
                                "div",
                                { staticClass: "friendTags" },
                                _vm._l(element.tags, function(tag) {
                                  return _c(
                                    "div",
                                    { key: tag, staticClass: "tagItem" },
                                    [_vm._v(_vm._s(tag))]
                                  )
                                }),
                                0
                              )
                            ]),
                            _c("div", { staticClass: "secondRowBox" }, [
                              _vm._v(
                                "\n                ID: " +
                                  _vm._s(element.uc_id) +
                                  "\n              "
                              )
                            ])
                          ])
                        ]),
                        _c(
                          "div",
                          { staticClass: "itemRight" },
                          [
                            element.hasSelected
                              ? _c("el-image", {
                                  staticStyle: {
                                    width: "20px",
                                    height: "20px"
                                  },
                                  attrs: {
                                    src: require("../../../assets/images/newLogin/hasSelected.png"),
                                    fit: "cover"
                                  }
                                })
                              : _c("el-image", {
                                  staticStyle: {
                                    width: "20px",
                                    height: "20px"
                                  },
                                  attrs: {
                                    src: require("../../../assets/images/newLogin/unSelected.png"),
                                    fit: "cover"
                                  }
                                })
                          ],
                          1
                        )
                      ]
                    )
                  })
                ],
                2
              )
            }),
            0
          )
        ])
      : _vm._e()
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "autoforwardingHeader" }, [
      _c("div", { staticClass: "autoforwardingSearch" }, [
        _c("div", { staticClass: "searchBox" }, [
          _c("span", { staticClass: "iconfont_Me icon-search iconStyle" })
        ]),
        _c("div", { staticClass: "searchInputBox" }, [
          _c("input", { staticClass: "inputElement", attrs: { type: "text" } })
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }