var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.dialogVisible
    ? _c(
        "el-dialog",
        {
          attrs: {
            width: "500px",
            center: true,
            title: _vm.dialogTitle,
            "destroy-on-close": true,
            visible: _vm.dialogVisible,
            "close-on-click-modal": false,
            "before-close": _vm.dialogBeforeClose
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogVisible = $event
            }
          }
        },
        [_vm._t("contentBox")],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }