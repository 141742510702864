var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return Object.keys(_vm.secureInfo).length
    ? _c(
        "ul",
        { staticClass: "settingAccount" },
        [
          _c("li", { staticClass: "settingAccount__item" }, [
            _c(
              "div",
              { staticClass: "settingAccount__item--left" },
              [
                _c(
                  "el-tooltip",
                  {
                    staticClass: "item",
                    attrs: {
                      effect: "dark",
                      content: _vm.$t("loginPassword"),
                      placement: "bottom"
                    }
                  },
                  [
                    _c("img", {
                      style: {
                        width: "24px",
                        height: "24px"
                      },
                      attrs: {
                        src: require("../../assets/images/newSetting/lockIcon.png")
                      }
                    })
                  ]
                ),
                _vm.secureInfo.hasLoginStrongPwd
                  ? _c("i", { staticClass: "el-icon--success" })
                  : _vm.$store.getters.userInfo.loginState ===
                      "melinkedLogin" && !_vm.secureInfo.hasLoginStrongPwd
                  ? _c(
                      "div",
                      { staticClass: "settingAccount__item--left--tips" },
                      [
                        _c("div", { staticClass: "strongPrompt" }, [
                          _vm._v("!")
                        ]),
                        _vm._v(
                          "\n        " +
                            _vm._s(_vm.$t("loginPwdTip")) +
                            "\n      "
                        )
                      ]
                    )
                  : _vm.$store.getters.userInfo.loginState ===
                      "thirdPartyLogin" && !_vm.secureInfo.hasLoginStrongPwd
                  ? _c(
                      "div",
                      { staticClass: "settingAccount__item--left--tips" },
                      [
                        _c("div", { staticClass: "strongPrompt" }, [
                          _vm._v("!")
                        ]),
                        _vm._v(
                          "\n        " +
                            _vm._s(_vm.$t("thirdPartyLoginTip")) +
                            "\n      "
                        )
                      ]
                    )
                  : _vm._e()
              ],
              1
            ),
            _c("div", { staticClass: "settingAccount__item--right" }, [
              _vm.secureInfo.hasLoginStrongPwd
                ? _c("span", { staticClass: "value" }, [_vm._v("*********")])
                : _vm._e(),
              _c("i", {
                staticClass: "el-icon-edit",
                on: {
                  click: function($event) {
                    return _vm.handlerShowDialog("password")
                  }
                }
              })
            ])
          ]),
          !_vm.secureInfo.hasSecureQuestion &&
          !_vm.secureInfo.secureEmail &&
          !_vm.secureInfo.securePhone &&
          (_vm.$store.getters.userInfo.loginState === "melinkedLogin" ||
            (_vm.$store.getters.userInfo.loginState === "thirdPartyLogin" &&
              _vm.secureInfo.hasLoginStrongPwd))
            ? _c(
                "li",
                { staticClass: "settingAccount__tip settingAccount__item" },
                [
                  _c("div", { staticClass: "strongPrompt" }, [_vm._v("!")]),
                  _vm._v("\n    " + _vm._s(_vm.$t("secursTip")) + "\n  ")
                ]
              )
            : _vm._e(),
          _vm._l(Object.keys(_vm.items), function(el, index) {
            return _vm.$store.getters.userInfo.loginState === "melinkedLogin" ||
              (_vm.$store.getters.userInfo.loginState === "thirdPartyLogin" &&
                _vm.secureInfo.hasLoginStrongPwd)
              ? _c("li", { key: index, class: ["settingAccount__item"] }, [
                  _c(
                    "div",
                    { staticClass: "settingAccount__item--left" },
                    [
                      _c(
                        "el-tooltip",
                        {
                          staticClass: "item",
                          attrs: {
                            effect: "dark",
                            content: _vm.items[el].tooltipContent,
                            placement: "bottom"
                          }
                        },
                        [
                          _c("img", {
                            style: {
                              width: "20px",
                              height: "20px"
                            },
                            attrs: {
                              src: require(`../../assets/images/newSetting/${el}Icon.png`)
                            }
                          })
                        ]
                      ),
                      _vm.items[el].success
                        ? _c("i", { staticClass: "el-icon--success" })
                        : _vm._e()
                    ],
                    1
                  ),
                  _c("div", { staticClass: "settingAccount__item--right" }, [
                    _vm.items[el].value
                      ? _c("span", { staticClass: "value" }, [
                          _vm._v(_vm._s(_vm.items[el].value))
                        ])
                      : _vm._e(),
                    _c("i", {
                      staticClass: "el-icon-edit",
                      on: {
                        click: function($event) {
                          return _vm.handlerShowDialog(el)
                        }
                      }
                    })
                  ])
                ])
              : _vm._e()
          }),
          _c(
            "CommonDialog",
            {
              attrs: {
                dialogVisible: _vm.reLoginVisible,
                dialogTitle: _vm.reLogin.title
              },
              on: { hiddenAccountBox: _vm.hideReLoginDialog }
            },
            [
              _c("div", { attrs: { slot: "contentBox" }, slot: "contentBox" }, [
                _c("div", { staticClass: "dialogMessageContent" }, [
                  _vm._v(
                    "\n        " + _vm._s(_vm.reLogin.message) + "\n      "
                  )
                ]),
                _c(
                  "div",
                  { staticClass: "elButtonBox" },
                  [
                    _c(
                      "el-button",
                      {
                        staticStyle: { width: "50%" },
                        attrs: { type: "info" },
                        on: {
                          click: function($event) {
                            return _vm.hideReLoginDialog()
                          }
                        }
                      },
                      [_c("i", { staticClass: "el-icon-close" })]
                    ),
                    _c(
                      "el-button",
                      {
                        staticStyle: { width: "50%" },
                        attrs: { type: "primary" },
                        on: {
                          click: function($event) {
                            return _vm.logOut()
                          }
                        }
                      },
                      [_c("i", { staticClass: "el-icon--logout" })]
                    )
                  ],
                  1
                )
              ])
            ]
          )
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }