var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "narrowBox my-setting" },
    [
      _c(
        "el-tabs",
        {
          staticClass: "settings-tabs",
          attrs: {
            "tab-position": _vm.tabPosition,
            "before-leave": _vm.beforeHandleTab
          },
          model: {
            value: _vm.active,
            callback: function($$v) {
              _vm.active = $$v
            },
            expression: "active"
          }
        },
        [
          _c("el-tab-pane", { attrs: { name: "setting" } }, [
            _c(
              "div",
              {
                staticClass: "settingSecurity tabTitle",
                attrs: { slot: "label" },
                slot: "label"
              },
              [
                _c("div", { staticClass: "securityIcon iconCommonStyle" }),
                _c(
                  "span",
                  {
                    staticClass: "settingTitle",
                    style: { marginLeft: "17px" }
                  },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("accountSecurity")) +
                        "\n        "
                    )
                  ]
                )
              ]
            ),
            _c("div", { staticClass: "setting accountAecurity" }, [
              _c("div", { staticClass: "userinfo__base" }, [
                _c("div", { staticClass: "settingBlockTitle" }, [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.$t("currentlyLoggedInAccount")) +
                      "\n          "
                  )
                ]),
                _c("div", { staticClass: "userInfoBox" }, [
                  _c("div", { staticClass: "userinfoLeft" }, [
                    _c(
                      "div",
                      { staticClass: "avatarBox" },
                      [
                        _c("Avatar", {
                          attrs: {
                            path: _vm.$store.getters.userInfo.avatar,
                            providerId: _vm.$store.getters.userInfo.id,
                            international: 0
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "userNameBox",
                        class: _vm.$store.getters.userInfo.vipStatus
                          ? "vipName"
                          : ""
                      },
                      [
                        _vm._v(
                          "\n                " +
                            _vm._s(_vm.$store.getters.userInfo.name) +
                            "\n                "
                        ),
                        _c(
                          "el-tooltip",
                          {
                            staticClass: "item",
                            attrs: {
                              effect: "dark",
                              content: _vm.$t("paidMember"),
                              placement: "bottom",
                              "popper-class": "me-tooltip-effect"
                            }
                          },
                          [
                            _c("VipIcon", {
                              attrs: {
                                vipStatus:
                                  _vm.$store.getters.userInfo.vipStatus,
                                iconWidth: 20,
                                iconHeight: 20,
                                id: _vm.$store.getters.userInfo.id
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c("div", { staticClass: "userIdBox" }, [
                      _c("div", { staticClass: "userIdIcon" }, [
                        _c("img", {
                          attrs: {
                            src: require("../assets/images/newPersonalCenter/idIcon.png")
                          }
                        })
                      ]),
                      _c("div", { staticClass: "userIdText" }, [
                        _vm._v(
                          "\n                  " +
                            _vm._s(_vm.$store.getters.userInfo.uc_id) +
                            "\n                "
                        )
                      ])
                    ])
                  ]),
                  _c("div", { staticClass: "userinfoRight" }, [
                    _c("img", {
                      staticStyle: {
                        width: "56px",
                        height: "56px",
                        "object-fit": "cover"
                      },
                      attrs: { src: _vm.dunImg, alt: "" }
                    }),
                    _c("div", { staticClass: "secureLeaveBox" }, [
                      _c("span", { staticClass: "secureLeaveTitle" }, [
                        _vm._v(
                          "\n                  " +
                            _vm._s(_vm.$t("secureLeave")) +
                            "\n                "
                        )
                      ]),
                      _c("span", { staticClass: "secureLevelText" }, [
                        _vm._v(
                          "\n                  " +
                            _vm._s(
                              `V${_vm.$store.state.accountStore.secureInfo
                                .secureLevel || 1}`
                            ) +
                            "\n                "
                        )
                      ])
                    ]),
                    _c(
                      "div",
                      { staticClass: "pross" },
                      [
                        _c("el-progress", {
                          class: [
                            "color-" +
                              _vm.$store.state.accountStore.secureInfo
                                .secureLevel
                          ],
                          attrs: {
                            "show-text": false,
                            "stroke-width": 18,
                            percentage: _vm.percentage
                          }
                        }),
                        _c(
                          "div",
                          {
                            staticClass: "tip",
                            on: {
                              click: function($event) {
                                return _vm.jumpPage({
                                  name: "wallet",
                                  params: {
                                    type: "setWallet"
                                  }
                                })
                              }
                            }
                          },
                          [
                            _vm._v(
                              "\n                  " +
                                _vm._s(
                                  !_vm.$store.state.accountStore.secureInfo
                                    .hasSecureWallet
                                    ? _vm.$t("setPaypwd")
                                    : !_vm.$store.state.accountStore.secureInfo
                                        .hasWalletStrongPwd
                                    ? _vm.$t("modifyPaypwd")
                                    : ""
                                ) +
                                "\n                "
                            )
                          ]
                        )
                      ],
                      1
                    )
                  ])
                ])
              ]),
              _c(
                "div",
                { staticClass: "accountSetting_box" },
                [_c("AccountSetting")],
                1
              )
            ])
          ]),
          _c("el-tab-pane", { attrs: { name: "settingSecurity" } }, [
            _c(
              "div",
              {
                staticClass: "settingSecurity tabTitle",
                attrs: { slot: "label" },
                slot: "label"
              },
              [
                _c("div", { staticClass: "privacyIcon iconCommonStyle" }),
                _c(
                  "span",
                  {
                    staticClass: "settingTitle",
                    style: { marginLeft: "17px" }
                  },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("privacyTitle")) +
                        "\n        "
                    )
                  ]
                )
              ]
            ),
            _c("div", { staticClass: "setting securityContent" }, [
              _c("div", { staticClass: "contentHeader" }, [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.$t("privacyProtection")) +
                    "\n        "
                )
              ]),
              _c("div", { staticClass: "contentBox" }, [
                _c(
                  "div",
                  { staticClass: "contentItem allowFriendsToChat" },
                  [
                    _c("span", { staticClass: "itemTitle" }, [
                      _vm._v(_vm._s(_vm.$t("allowFriendsToChat")))
                    ]),
                    _c("el-switch", {
                      on: { change: _vm.changeAllowToChat },
                      model: {
                        value: _vm.allowToChat,
                        callback: function($$v) {
                          _vm.allowToChat = $$v
                        },
                        expression: "allowToChat"
                      }
                    })
                  ],
                  1
                )
              ])
            ])
          ]),
          _c("el-tab-pane", { attrs: { name: "autoForwarded" } }, [
            _c(
              "div",
              {
                staticClass: "settingSecurity tabTitle",
                attrs: { slot: "label" },
                slot: "label"
              },
              [
                _c("div", { staticClass: "forwardIcon iconCommonStyle" }),
                _c("span", { staticClass: "settingTitle" }, [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.$t("automaticForwarding")) +
                      "\n        "
                  )
                ])
              ]
            ),
            _c(
              "div",
              { staticClass: "setting tranpane" },
              [
                _c("AutoforwardingForSettingPage", {
                  attrs: {
                    curUser: _vm.curUser,
                    handleFriendsList: _vm.handleFriendsList
                  },
                  on: {
                    delTranUser: _vm.delTranUser,
                    chooseThisFriend: _vm.chooseThisFriend
                  }
                })
              ],
              1
            )
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }