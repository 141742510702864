<template>
  <ul class="settingAccount" v-if="Object.keys(secureInfo).length">
    <li class="settingAccount__item">
      <div class="settingAccount__item--left">
        <el-tooltip
          class="item"
          effect="dark"
          :content="$t('loginPassword')"
          placement="bottom"
        >
          <img
            :src="require('../../assets/images/newSetting/lockIcon.png')"
            :style="{
              width: '24px',
              height: '24px',
            }"
          />
        </el-tooltip>
        <i v-if="secureInfo.hasLoginStrongPwd" class="el-icon--success"></i>
        <div
          v-else-if="
            $store.getters.userInfo.loginState === 'melinkedLogin' &&
              !secureInfo.hasLoginStrongPwd
          "
          class="settingAccount__item--left--tips"
        >
          <div class="strongPrompt">!</div>
          {{ $t("loginPwdTip") }}
        </div>
        <div
          v-else-if="
            $store.getters.userInfo.loginState === 'thirdPartyLogin' &&
              !secureInfo.hasLoginStrongPwd
          "
          class="settingAccount__item--left--tips"
        >
          <div class="strongPrompt">!</div>
          {{ $t("thirdPartyLoginTip") }}
        </div>
      </div>
      <div class="settingAccount__item--right">
        <span v-if="secureInfo.hasLoginStrongPwd" class="value">*********</span>
        <i class="el-icon-edit" @click="handlerShowDialog('password')"></i>
      </div>
    </li>
    <li
      v-if="
        !secureInfo.hasSecureQuestion &&
          !secureInfo.secureEmail &&
          !secureInfo.securePhone &&
          ($store.getters.userInfo.loginState === 'melinkedLogin' ||
            ($store.getters.userInfo.loginState === 'thirdPartyLogin' &&
              secureInfo.hasLoginStrongPwd))
      "
      class="settingAccount__tip settingAccount__item"
    >
      <div class="strongPrompt">!</div>
      {{ $t("secursTip") }}
    </li>
    <!-- 只有当登录用户是melinked账号登录的时候或者是第三方登录但是设置了强密码的时候，才可以设置安全信息 -->
    <li
      v-for="(el, index) in Object.keys(items)"
      :key="index"
      :class="['settingAccount__item']"
      v-if="
        $store.getters.userInfo.loginState === 'melinkedLogin' ||
          ($store.getters.userInfo.loginState === 'thirdPartyLogin' &&
            secureInfo.hasLoginStrongPwd)
      "
    >
      <div class="settingAccount__item--left">
        <el-tooltip
          class="item"
          effect="dark"
          :content="items[el].tooltipContent"
          placement="bottom"
        >
          <img
            :src="require(`../../assets/images/newSetting/${el}Icon.png`)"
            :style="{
              width: '20px',
              height: '20px',
            }"
          />
        </el-tooltip>
        <i v-if="items[el].success" class="el-icon--success"></i>
      </div>
      <div class="settingAccount__item--right">
        <span v-if="items[el].value" class="value">{{ items[el].value }}</span>
        <i class="el-icon-edit" @click="handlerShowDialog(el)"></i>
      </div>
    </li>
    <!-- 第三方用重新登录 -->
    <CommonDialog
      :dialogVisible="reLoginVisible"
      @hiddenAccountBox="hideReLoginDialog"
      :dialogTitle="reLogin.title"
    >
      <div slot="contentBox">
        <div class="dialogMessageContent">
          {{ reLogin.message }}
        </div>
        <div class="elButtonBox">
          <el-button
            :type="'info'"
            @click="hideReLoginDialog()"
            style="width:50%"
          >
            <i class="el-icon-close" />
          </el-button>
          <el-button :type="'primary'" @click="logOut()" style="width:50%">
            <i class="el-icon--logout" />
          </el-button>
        </div>
      </div>
    </CommonDialog>
  </ul>
</template>
<script>
import { securityCheck } from "@/newVersion/components/accountSecurityDialog/api";
// 弹窗组件
import CommonDialog from "../../components/common/dialog/commonDialog";
// 关闭聊天socket
import { close as serverClose } from "@/components/business/chat/server";
import { env } from "@/utils";

export default {
  name: "SettingAccount",
  provide() {
    return {
      setting: this,
    };
  },
  components: { CommonDialog },
  data() {
    return {
      // 重新登录弹窗状态
      reLoginVisible: false,
      // 重新登录弹窗展示数据
      reLogin: {
        title: "",
      },
      password: { icon: "el-icon--pwd" },
      items: {
        phone: {
          value: "",
          success: false,
          tooltipContent: this.$t("phoneNumber"),
        },
        email: {
          value: "",
          success: false,
          tooltipContent: this.$t("tooltipEmail"),
        },
        question: {
          value: "",
          success: false,
          tooltipContent: this.$t("tooltipIssues"),
        },
      },
      types: ["password", "phone", "email", "question"],
    };
  },
  watch: {
    // 监听是否需要执行更新用户安全信息方法
    "$store.state.accountSecurity.needGetUserinfoStatus": {
      async handler(val, old) {
        if (val && val !== old) {
          await this.handlerGetUserinfo();
          this.$store.commit("setNeedGetUserinfoStatus", false);
        }
      },
      deep: true,
    },
    // 监听修改设置完密码后是否需要关闭弹窗
    "$store.state.accountSecurity.closeDialogAfterChangingThePassword": {
      async handler(val, old) {
        if (val && val !== old) {
          await this.handlerTip();
          this.$store.commit("setCloseDialogAfterChangingThePassword", false);
        }
      },
      deep: true,
    },
  },
  computed: {
    secureInfo() {
      return this.$store.state.accountStore.secureInfo;
    },
  },
  async created() {
    await this.handlerGetUserinfo();
  },
  methods: {
    // 关闭重新登录弹窗
    hideReLoginDialog() {
      this.reLoginVisible = false;
    },
    // 关闭弹窗
    async handlerTip(result) {
      let type = "success";
      let message = this.$t("successText");
      if (result && result.code !== 200) {
        type = "error";
        message =
          result.message ||
          result.data.message ||
          this.$t("phoneOrverificationErr");
      } else {
        this.$store.commit("setAccountSecurityDialogStatus", false);
        this.$store.commit("setNeedShowAccountSecurityComponentName", "");
        // 设置完毕刷新安全信息
        await this.handlerGetUserinfo();
      }
      this.routeJump({ name: "home" });
      this.$nextTick(() => {
        this.$message({
          type,
          message: message,
        });
      });
    },
    // 打开弹窗
    async handlerShowDialog(type) {
      if (!this.types.includes(type)) {
        return;
      }
      // 如果是设置登录密码，判断是否是第三方登录并且还没设置过强密码，默认是没设置过登录密码的三方用户
      if (
        type == "password" &&
        this.$store.getters.userInfo.loginState == "thirdPartyLogin" &&
        !this.secureInfo.hasLoginStrongPwd
      ) {
        await this.verifyThirdParty();
      } else {
        this.$store.commit("setAccountSecurityDialogStatus", true);
        this.$store.commit("setNeedShowAccountSecurityComponentName", type);
      }
    },
    // 设置登录密码时，第三方登录验证时效
    async verifyThirdParty() {
      const params = {
        codeType: "5",
        providerId: this.secureInfo.providerId,
      };
      const result = await securityCheck(params);
      if (result && result.code === 200) {
        this.$store.commit("setAccountSecurityDialogStatus", true);
        this.$store.commit(
          "setNeedShowAccountSecurityComponentName",
          "setNewPassword"
        );
      } else if (result && result.code === 406) {
        this.reLogin.title = this.$t("reLogin");
        this.reLogin.message = result.message;
        this.reLoginVisible = true;
      } else {
        this.$message({
          type,
          message: result.message || result.data.message,
        });
      }
    },
    // 选择重新登录
    logOut() {
      this.$confirm("", "", {
        confirmButtonText: " ",
        cancelButtonText: " ",
        type: "warning",
        center: true,
        iconClass: "el-icon--logout",
        customClass: "my-confirm",
        cancelButtonClass: "el-icon-close my-confirm-btn",
        confirmButtonClass: "el-icon-check my-confirm-btn",
      })
        .then(async () => {
          serverClose();
          this.$store.commit("setUserinfo", {});
          if (this.$cookies.get("oauth_user_id")) {
            this.$cookies.remove("oauth_user_id");
          }
          await this.$http({
            url: "/helome/logout.do",
            method: "get",
          });
          this.reLoginVisible = false;
          window.location.href = env("BASEURL");
        })
        .catch((e) => {
          console.log(e);
        });
    },
    // 获取用户安全信息
    handlerGetUserinfo() {
      return new Promise(async (resolve, reject) => {
        await this.$store.dispatch(
          "commitSetSecureInfo",
          this.$store.getters.userInfo.account ||
            this.$store.getters.userInfo.id
        );
        this.items.email.value = this.secureInfo.secureEmail;
        this.items.phone.value =
          this.secureInfo.securePhone !== ""
            ? `+${this.secureInfo.securePhone}`
            : "";
        this.items.email.success = !!this.secureInfo.secureEmail;
        this.items.phone.success = !!this.secureInfo.securePhone;
        this.items.question.success = this.secureInfo.hasSecureQuestion;
        if (this.secureInfo.securePhone && this.secureInfo.securePhone !== "") {
          try {
            this.$store.commit(
              "setSecurityPhoneAreaCode",
              this.secureInfo.securePhone.split("-")[0]
            );
          } catch (e) {}
        }
        resolve("success");
      });
    },
  },
};
</script>
<style lang="stylus">
@import '~styles/utils/index';
  +b(setting){
    +e(phone){
      +m(country){
        .el-cascader{
          height 38px
          min-width 80px
          .el-input{
            +e(suffix){
              height 38px
              line-height 38px
              right 30px
            }
            +e(icon){
              line-height 38px
            }
            input{
              border 0
              background none
              line-height 38px
              height 38px
              // max-width 100px
            }
          }
        }
      }
    }
    +e(dialog){
      .el-form{
        .el-form-item{
            +e(content){
              .el-input-group{
                +e(prepend){
                  background none
                }
                +e(append){
                  background none
                  cursor pointer
                }
              }
                .el-button{
                    width 100%
                    font-size 18px
                    &.el-button--info{
                      // background #cccccc
                    }
                }
            }
        }
    }
    }
  }
+b(settingAccount){
    padding 30px 0 20px 0
    display flex
    flex-flow column
    +e(tip){
        color red !important;
        padding 3.5rem 0.9rem .9rem  !important
        font-size 13px
        justify-content flex-start !important
        i{
            margin-right .5rem
            font-size 18px
        }
    }
    +e(item){
        color #666666
        padding 1.5rem 0;
        border-top 1px solid #e5e5e5
        [class^='el-icon--'],[class*='el-icon--']{
              font-size 20px

            }
        .question{
            font-size 14px
            font-style normal
        }
        display flex
        flex-flow row
        justify-content space-between
        +m(left){
          display flex
          flex-flow row
          .el-icon--success{
              color #33cc66
              margin-left .5rem
          }
          +m(tips){
            margin-left .5rem
            font-size 13px
            color red !important;
            display flex;
            align-items center;
            justify-content flex-start;
          }
        }
        +m(right){
          .el-icon-edit{
              cursor pointer
          }
          .value{
              margin-right .5rem
          }
        }
        .strongPrompt {
          width 15px;
          height 15px;
          border-radius 50%;
          background red;
          color #FFFFFF;
          font-size 10px;
          text-align center;
          line-height 15px;
          margin-right 8px;
        }
    }
}
.el-dialog{
  margin-top 0 !important;
}
.dialogMessageContent{
  text-align: center;
  padding: 20px 0;
}
.elButtonBox{
  width: 100%;
  margin-top: 10px;
  display: flex;
  flex-flow: row;
  el-button{
    flex: 1;
  }
}
</style>
