<template>
  <el-dialog
    width ="500px"
    :center ="true"
    v-if="dialogVisible"
    :title ="dialogTitle"
    :destroy-on-close ="true"
    :visible.sync ="dialogVisible"
    :close-on-click-modal ="false"
    :before-close ="dialogBeforeClose"
  >
    <slot name="contentBox"></slot>
  </el-dialog>
</template>
<script>
export default {
  name: "CommonDialog",
  data() {
    return { };
  },
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    },
    dialogTitle: {
      type: String,
      default: ""
    },
  },
  methods: {
    dialogBeforeClose() {
      this.$emit("hiddenAccountBox");
    },
  }
};
</script>