<template>
  <div class="autoforwardingBox">
    <div class="autoforwardingSwitchBox">
      {{ $t("automaticForwarding") }}
      <el-switch
        v-model="allowChangeAutoforwarding"
      ></el-switch>
    </div>
    <div class="autoforwardingInner" v-if="allowChangeAutoforwarding">
      <div class="curUserBox" v-if="curUser.provider_id">
        <div class="curUserInner">
          <div class="curUserAvatar">
            <Avatar
              :path="curUser.avatar"
              :international="curUser.country?Number(curUser.country) : 0"
            ></Avatar>
          </div>
          <div class="curUserInfo">
            <div class="firstRowBox">
              <div class="friendName">
                {{curUser.name}}
              </div>
            </div>
            <div class="secondRowBox">
              ID: {{curUser.uc_id}}
            </div>
          </div>
          <div class="curUserCloseBox">
            <div class="closeIcon" @click.stop="delTranUser">
              <span class="iconfont_Me icon-x iconStyle"></span>
            </div>
          </div>
        </div>
      </div>
      <div class="autoforwardingHeader">
        <div class="autoforwardingSearch">
          <div class="searchBox">
            <span class="iconfont_Me icon-search iconStyle"></span>
          </div>
          <div class="searchInputBox">
            <input type="text" class="inputElement">
          </div>
        </div>
      </div>
      <div class="autoforwardingContent">
        <div 
          class="blockItem" 
          v-for="(item,index) in handleFriendsList" 
          :key="item.name_first_spell"
        >
          <div class="itemTitle">
            {{item.name_first_spell}}
          </div>
          <div 
            class="friendItem"
            v-for="element in item.childrens"
            :key="element.provider_id"
            @click.stop="chooseThisFriend(element)"
          >
            <div class="itemLeft">
              <div class="friendAvatar">
                <Avatar
                  :path="element.avatar"
                  :international="element.country?Number(element.country) : 0"
                ></Avatar>
              </div>
              <div class="friendInfoBox">
                <div class="firstRowBox">
                  <div class="friendName">
                    {{element.name}}
                  </div>
                  <div class="friendTags">
                    <div class="tagItem" v-for="tag in element.tags" :key="tag">{{tag}}</div>
                  </div>
                </div>
                <div class="secondRowBox">
                  ID: {{element.uc_id}}
                </div>
              </div>
            </div>
            <div class="itemRight">
              <el-image
                v-if="element.hasSelected"
                style="width: 20px; height: 20px"
                :src="require('../../../assets/images/newLogin/hasSelected.png')"
                :fit="'cover'"></el-image>
              <el-image
                v-else
                style="width: 20px; height: 20px"
                :src="require('../../../assets/images/newLogin/unSelected.png')"
                :fit="'cover'"></el-image>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Avatar from "../../components/avatarDisplay";
export default {
  name: "autoforwardingForSettingPage",
  components: {
    Avatar
  },
  props: {
    // 被选中的好友
    curUser: {
      type: Object,
      default: () => {
        return {}
      }
    },
    // 处理过的好友列表
    handleFriendsList: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  data() {
    return {
      allowChangeAutoforwarding: false
    }
  },
  watch: {
    // 监听是否有被选中转发的好友
    curUser: {
      handler(val,old) {
        if(val.name && val.name !== "") {
          this.allowChangeAutoforwarding = true;
        }
      },
      deep: true
    },
    // 监听自动转发的状态
    allowChangeAutoforwarding: {
      handler(val,old) {
        if(!val && val !== old) {
          // 当有转发对象的时候
          if(this.curUser.name && this.curUser.name !== "") {
            this.delTranUser();
          }
        }
      },
      deep: true
    }
  },
  methods: {
    // 选择这个好友
    chooseThisFriend(friendElement) {
      this.$emit("chooseThisFriend",friendElement)
    },
    // 删除这个转发对象
    delTranUser() {
      this.allowChangeAutoforwarding = false;
      this.$emit("delTranUser",this.curUser)
    }
  }
}
</script>
<style lang="stylus" scoped>
  .autoforwardingBox
    width 100%;
    height 100%;
    box-sizing border-box;
    padding 40px 80px;
    display flex;
    flex-flow column;
    align-items center;
    justify-content flex-start;
    .autoforwardingSwitchBox
      width 100%;
      height 20px;
      flex-shrink 0;
      padding 25px 0;
      font-size 14px;
      color #373737;
      display flex;
      align-items center;
      box-sizing content-box;
      justify-content space-between;
    .autoforwardingInner
      width 100%;
      flex 1;
      min-height 0;
      background #FAFAFA;
      display flex;
      flex-flow column;
      align-items center;
      justify-content flex-start;
      .curUserBox
        width 100%;
        height 100px;
        padding 0 30px;
        box-sizing border-box;
        border-bottom 1px solid #E6E6E6;
        display flex;
        align-items center;
        justify-content center;
        .curUserInner
          width 100%;
          height 50px;
          display flex;
          align-items center;
          justify-content flex-start;
          .curUserAvatar
            height 50px;
            width 50px;
            flex-shrink 0;
          .curUserInfo
            height 50px;
            max-width 200px;
            padding-left 10px;
            box-sizing border-box;
            display flex;
            flex-flow column;
            align-items center;
            justify-content space-around;
            .firstRowBox
              width 100%;
              height 24px;
              display flex;
              align-items center;
              justify-content flex-start;
              .friendName
                line-height 24px;
                font-size 14px;
                font-weight bold;
                color #333333;
                flex-shrink 0;
                min-width 0;
                margin-right 10px;
            .secondRowBox
              width 100%;
              height 16px;
              line-height 16px;
              font-size 12px;
              color #8F8F8F;
              text-align left;
          .curUserCloseBox
            width 16px;
            height 100%;
            margin-left 10px;
            display flex;
            align-items center;
            justify-content center;
            .closeIcon
              width 14px;
              height 14px;
              cursor pointer;
              border-radius 50%;
              background rgba(0,0,0,0.3);
              display flex;
              align-items center;
              justify-content center;
              .iconStyle
                font-size 14px;
                color #FFFFFF;
      .autoforwardingHeader
        width 100%;
        height 60px;
        padding 26px 26px 0 26px;
        box-sizing border-box;
        .autoforwardingSearch
          width 100%;
          height 100%;
          background #F0F0F0;
          display flex;
          align-items center;
          justify-content flex-start;
          .searchBox
            width 40px;
            height 100%;
            flex-shrink 0;
            display flex;
            align-items center;
            justify-content center;
            .iconStyle
              font-size 18px;
              color #BFBFBF;
          .searchInputBox
            flex 1;
            min-width 0;
            height 100%;
            .inputElement
              width 100%;
              height 100%;
              font-size 14px;
              color #343434;
              border none;
              background transparent;
              outline none;
              caret-color #33CC66;
              &::-webkit-input-placeholder
                color: #CCCCCC;
              &::-moz-input-placeholder
                color: #CCCCCC;
              &::-ms-input-placeholder
                color: #CCCCCC;
      .autoforwardingContent
        flex 1;
        width 100%;
        min-height 0;
        overflow-x hidden;
        overflow-y auto;
        padding 0 26px 18px 26px;
        box-sizing border-box;
        &::-webkit-scrollbar
          width 4px;
        &::-webkit-scrollbar-thumb
          border-radius: 2px;
          box-shadow: inset 0 0 5px #ADADAD;
          background: #ADADAD;
        &::-webkit-scrollbar-track
          box-shadow: inset 0 0 5px rgba(0,0,0,0);
          border-radius: 0;
          background: rgba(0,0,0,0);
        .blockItem
          width 100%;
          overflow hidden;
          padding-top 18px;
          .itemTitle
            width 100%;
            height 26px;
            line-height 26px;
            padding 0 10px;
            box-sizing border-box;
            background #F0F0F0;
            font-size 14px;
            color #333333;
            user-select none;
          .friendItem
            height 50px;
            margin-top 18px;
            padding 0 10px;
            cursor pointer;
            user-select none;
            display flex;
            align-items center;
            justify-content flex-start;
            .itemLeft
              flex 1;
              height 50px;
              display flex;
              align-items center;
              justify-content flex-start;
              .friendAvatar
                width 50px;
                height 50px;
                flex-shrink 0;
              .friendInfoBox
                flex 1;
                min-width 0;
                height 50px;
                box-sizing border-box;
                padding-left 10px;
                display flex;
                flex-flow column;
                align-items center;
                justify-content space-around;
                .firstRowBox
                  width 100%;
                  height 24px;
                  display flex;
                  align-items center;
                  justify-content flex-start;
                  .friendName
                    line-height 24px;
                    font-size 14px;
                    font-weight bold;
                    color #333333;
                    flex-shrink 0;
                    min-width 0;
                    margin-right 10px;
                  .friendTags
                    flex 1;
                    min-width 0;
                    height 100%;
                    overflow hidden;
                    .tagItem
                      height 100%;
                      line-height 24px;
                      padding 0 10px;
                      border-radius 12px;
                      background #F2FCF8;
                      font-size 12px;
                      color #1D4329;
                      display inline-block;
                .secondRowBox
                  width 100%;
                  height 16px;
                  line-height 16px;
                  font-size 12px;
                  color #8F8F8F;
                  text-align left;
            .itemRight
              width 24px;
              height 50px;
              flex-shrink 0;
              display flex;
              align-items center;
              justify-content center;

</style>